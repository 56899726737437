<template>
  <v-container>
    <v-row style="margin-top: 80px;">
      <v-col cols="6" class="mx-auto" style="text-align: center">
        <v-img
          aspect-ratio="1/1"
          width="150"
          height="150"
          contain
          src="../assets/notfound.png"
          class="mx-auto"
        ></v-img>
        <h1 class="title1">
          Social Audios are not available on the website!
        </h1>
        <br />
        <h4 class="subTitle1">
          Please download the Bayon App and listen the audios.
        </h4>
        <br />
        <v-btn to="/download" color="purple" style="color: white"
          >Download App</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "socialVideo",
  mounted() {
    this.$jQuery("body").off();
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  created() {},
};
</script>

<style>
.title1 {
  color: teal;
  size: 30px;
}

.title2 {
  color: teal;
}

.subTitle1 {
  size: 12px;
  color: gray;
}

.subTitle2 {
  size: 12px;
  text-align: justify !important;
}
</style>
